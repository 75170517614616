import React from 'react';

function App() {
  return (
    <main className='App'>
      <h1>Start</h1>
    </main>
  );
}

export default App;